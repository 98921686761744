<mat-toolbar class="bg-dark" class="crm-navigation-bar">
  <mat-toolbar-row class="flex">
    <button class="flex-start" mat-icon-button (click)="toggleSidenav.emit(true)">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="form-inline left">
      <a class="navbar-brand" href="https://gpmdev.co.uk" target="_blank"><img src="../../../../assets/images/GPM/GPM_Logo_RGB.png" width="60" height="40"></a>      
      <button class="btn btn-primary" onclick="window.open('https:\/\/gpmdev.zendesk.com/hc/en-gb', '_blank')">Support</button>
    </div>
    <img src="../../../../assets/images/GPM/Hub_1.png" width="40" height="40" class="mr-2"><h1><b>inFOCUS | HUB</b></h1>
    <div class="right"></div>
    <div class="flex-end">

      <ul class="navigation-items">
        <li>
          <button mat-icon-button [matMenuTriggerFor]="auth">
            <mat-icon> person </mat-icon>
          </button>
          <mat-menu #auth="matMenu">

            <nav-item mat-menu-item >
              <mat-icon> perm_identity </mat-icon>
              <span>{{getName()}}</span>
            </nav-item>

            <mat-divider></mat-divider>

            <nav-item mat-menu-item >
              <mat-icon> business </mat-icon>
              <span>{{getGroup()}}</span>
            </nav-item>

            <mat-divider></mat-divider>

            <nav-item mat-menu-item >
              <mat-icon> mail_outline </mat-icon>
              <span>{{getEmail()}}</span>
            </nav-item>

            <mat-divider></mat-divider>

            <nav-item mat-menu-item (click)="onLogout()">
              <mat-icon> exit_to_app </mat-icon>
              <span><input type="submit" class="btn btn-primary" value="Exit" (click)="onLogout()"></span>
            </nav-item>

          </mat-menu>
        </li>

      </ul>

    </div>

  </mat-toolbar-row>

</mat-toolbar>
