// Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ClipboardModule } from 'ngx-clipboard';

// import { MessageManagementModule } from './message-management/message-management.module';

// Directives
import { DragDropDirective } from './shared/directives/file-upload.directive';
// Pipes
import { FileSizePipe } from './shared/pipes/file-size.pipe';
// HTTP Interceptors
// ERROR Interceptors
import { ErrorIntercept } from './services/error.interceptor';

// MSAL
import { MsalModule,
  MsalInterceptor,
  MsalService,
  MsalGuard,
  MsalRedirectComponent,
  MsalBroadcastService
 } from '@azure/msal-angular';
 import { InteractionType, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
 import { Configuration } from 'msal';

// User settings
import { environment } from '../environments/environment';

// Components
import { GooglePlacesComponent } from './google-places/google-places.component';
import { AppComponent } from './app.component';
import { AboutComponent } from './shared/components/about/about.component';
import { ContactComponent } from './shared/components/contact/contact.component';
import { CompanyBrokerStatusComponent } from './shared/components/company-broker-status/company-broker-status.component';
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { AcordMessagesComponent } from './message-management/components/acord-messages/acord-messages.component';
import { AcordMessageDetailComponent } from './message-management/components/acord-messages/acord-message-detail/acord-message-detail.component';
import { AcordMessageSubmitComponent } from './message-management/components/acord-messages/acord-message-submit/acord-message-submit.component';
import { AcordMessageResubmitComponent } from './message-management/components/acord-messages/acord-message-resubmit/acord-message-resubmit.component';
import { RequestsComponent } from './message-management/components/requests/requests.component';
import { RequestDetailComponent } from './message-management/components/requests/request-detail/request-detail.component';
import { EcfCreateComponent } from './message-management/components/ecf-create/ecf-create.component';
import { RouterModule } from '@angular/router';
//import { NavComponent } from './shared/components/layout/nav/nav.component';
  import { NavigationBarComponent } from './shared/components/layout/navigation-bar/navigation-bar.component';
  import { LoginPageComponent } from './shared/components/loginPage/loginPage.component';
import { AuthService } from './shared/Authentication/auth.service';
export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    //directives
    DragDropDirective,
    //pipes
    FileSizePipe,
    GooglePlacesComponent,
    AppComponent,    
    AboutComponent,
    ContactComponent,
    AcordMessagesComponent,
    AcordMessageDetailComponent,
    AcordMessageSubmitComponent,
    AcordMessageResubmitComponent,
    RequestsComponent,
    RequestDetailComponent,
    EcfCreateComponent,
    FileUploadComponent,
    CompanyBrokerStatusComponent,
    //NavComponent,
    NavigationBarComponent,
    LoginPageComponent
  ],
  imports: [
    RouterModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    AngularEditorModule,
    SharedModule,
    //MessageManagementModule,
    ClipboardModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.OAuthSettings.clientId,
        authority: environment.OAuthSettings.authority,
        redirectUri: environment.OAuthSettings.redirectUri,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        [environment.infocusIMRAPI + '*', null]
      ])
    })
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorIntercept,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  constructor() { }
 }
