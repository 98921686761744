import { Injectable, Inject, OnDestroy } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { AlertsService } from '../Authentication/alerts.service';
import { environment } from '../../../environments/environment';
import { User } from '../code/user';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export class AuthInfo {
  public authenticated: boolean;
  public user?: User;
  public groupNames?: string[];
  public appRoleName?: string;
  public httpOptions?: any;
  public idToken?: string;
  public displayName?: string;
  public email?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  public authEmitter = new BehaviorSubject<AuthInfo>({ authenticated: false });

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private alertsService: AlertsService
  ) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        //console.log(`result: ${result}`);
        const payload = result.payload as AuthenticationResult;
        //console.log(`payload: ${payload}`);
        this.msalService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((status) => {
        this.initUserInfo();
      });
  }

  private initUserInfo(): void {
    let authInfo = new AuthInfo();
    authInfo.authenticated = this.msalService.instance.getAllAccounts() != null;
    this.getUser(authInfo)
      .then((authUser) => {
        if (authUser) {
          authInfo.user = { ...authUser };

          if (authInfo.user.groups.length > 0) {
            authInfo.groupNames = authInfo.user.groups.map((g) => g.groupName);
            this.SetHttpOptions(authInfo);
          }
        }
        return authInfo;
      })
      .then((authInfo) => this.authEmitter.next(authInfo));
  }

  public login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  public logout() {
    this.msalService.logoutRedirect();
  }

  // Silently request an access token
  private async getAccessToken(authInfo: AuthInfo): Promise<string> {
    let result = await this.msalService
      .acquireTokenSilent(environment.OAuthSettings)
      .toPromise()
      .catch((reason) => {
        //console.log('getAccessToken: ' + reason);
        this.alertsService.addError(
          'Get token failed',
          JSON.stringify(reason, null, 2)
        );
      });

    if (result) {
      authInfo.idToken = result.idToken;
      return result.accessToken;
    }

    // Couldn't get a token
    authInfo.authenticated = false;
    return null;
  }

  // <getUserSnippet>
  public async getUser(authInfo: AuthInfo): Promise<User> {
    if (!authInfo.authenticated) return null;

    // Initialize the Graph (GET /me)
    let graphClient = Client.init({
      // Initialize the Graph client with an auth provider
      // that requests the token from the auth service
      authProvider: async (done) => {
        let token = await this.getAccessToken(authInfo).catch((reason) => {
          done(reason, null);
        });

        if (token) {
          done(null, token);
        } else {
          done('Could not get an access token', null);
        }
      },
    });

    // Get the user from Graph (GET /me)
    let graphUser: MicrosoftGraph.User = await graphClient.api('/me').get();

    let authUser = new User();
    authUser.id = graphUser.id;
    authUser.displayName = graphUser.displayName;
    authInfo.displayName = graphUser.displayName;
    authInfo.email = graphUser.mail || graphUser.userPrincipalName;
    // Prefer the mail property, but fall back to userPrincipalName
    authUser.email = graphUser.mail || graphUser.userPrincipalName;
    authUser.principalName = graphUser.userPrincipalName;

    // Get the user groups from Graph (GET /users/graphUser.id/memberOf`)
    let graphUserGroups = await graphClient
      .api(
        '/users/' +
          graphUser.id +
          '/memberOf/microsoft.graph.group?&$select=displayName,id'
      )
      .get();

    (graphUserGroups.value as any[])
      .filter(g => (g.displayName as string).length === 3)
      .forEach((group) => {
      authUser.groups.push({
        groupId: group.id,
        groupName: group.displayName,
      });
    });
    // Get the user roles from Graph (GET /users/graphUser.id/appRoleAssignments`)
    let graphUserRoles = await graphClient
      .api('/users/' + graphUser.id + '/appRoleAssignments')
      .get();

    graphUserRoles.value.forEach((role) => {
      authUser.appRoles.push({
        appRoleId: role.appRoleId,
        appRoleName: environment.OAuthSettings.appRoles[role.appRoleId],
      });
    });
    // console.log("Role Name =" + authUser.appRoles[0].appRoleName);

    return authUser;
  }
  // </getUser>

  // Get httpOptions for include group in headers
  private SetHttpOptions(authInfo: AuthInfo): void {
    authInfo.httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        accept: 'application/json',
        authorization: 'Bearer ' + authInfo.idToken,
        groups: authInfo.groupNames,
        email: authInfo.user.email,
      }),
    };
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
