<div class="jumbotron">
  <div class="card">
    <div class="card-header">
      <div class="d-flex bd-highlight">
        <div class="p-2 flex-fill bd-highlight">
          <h3 >Requests</h3>
        </div>
      </div>
    </div>
    <div class="card-body">
      <p *ngIf="!dataSource"><mat-spinner></mat-spinner></p>
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
      </mat-form-field>
      <mat-table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="umr">
            <mat-header-cell *matHeaderCellDef><b>UMR </b> </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.umr}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ucr">
            <mat-header-cell *matHeaderCellDef> <b>UCR </b> </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ucr}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tr">
            <mat-header-cell *matHeaderCellDef><b> TR </b> </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.tr}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <mat-header-cell *matHeaderCellDef><b> Creation Date </b> </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.creationDate}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="technicianName">
            <mat-header-cell *matHeaderCellDef><b> Technician Name</b></mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.technicianName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="classOfBusiness">
            <mat-header-cell *matHeaderCellDef> <b> ClassOfBusiness </b> </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.classOfBusiness}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="telephone">
            <mat-header-cell *matHeaderCellDef> <b> Telephone </b> </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.telephone}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> <b> Email </b> </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="submissionType">
            <mat-header-cell *matHeaderCellDef> <b> Submission Type </b> </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.submissionType}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <a [routerLink]="['/requests/requestdetail/', element.id]">
                                    <mat-icon mat-list-icon>zoom_in</mat-icon>
                                </a>
                            </td>                     
                        </tr>
                    </tbody>
                </table>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20, 30]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>