<div class="jumbotron">
    <div class="row">
        <div class="card">
            <div class="card card-header">
                <h3>About</h3>
            </div>
            <div class="card card-body">
                <p>
                    GPM is a leading London-based software and IT development company specialising in insurance intermediary software and related services.
                    Established in 1988 we deliver flexible solutions to many varied operations in the UK and overseas and are also acknowledged as specialist supplier to the Lloyd’s of London market.
                    <br/>
                    <br/>
                    We always work closely with our clients to build enduring relationships, take time to learn about their evolving needs and use our extensive knowledge and experience to provide solutions which exceed expectations.
                    <br/>
                    <br/>
                    The GPM team’s products and services increase competitive advantage through efficiency savings, coupled with licensing arrangements that are precisely designed to future-proof the client’s investment.
                    <br/>
                    <br/>
                    Most importantly, our business and our software is secure, reliable and field-proven so our clients can be sure that they have chosen the right technology partner to embrace growth and be able to tackle new challenges and market trends.
                </p>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="card">
            <div class="card-header">
                <h3>Contact</h3>
            </div>
            <div class="card-body">
                <address>
                    6th Floor, AMP House<br />
                    Dingwall Road, Croydon<br />
                    CR0 2LX<br />
                                
                    <strong>Phone:</strong>020 8686 9400<br />
                    <strong>Info:</strong><a href="mailto:info@gpmdev.co.uk">info@gpmdev.co.uk</a>
                </address>
            </div>
        </div>
    </div>
</div>