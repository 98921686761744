import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { RequestDto  } from '../shared/code/InfocusInterfaces';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
 serviceURL: string;

  constructor(private baseService: BaseService) {
    this.serviceURL = environment.infocusIMRAPI;
   }

  getRequests() {
      return this.baseService.get(this.serviceURL + 'api/Request/GetRequests');
  }

  getRequestsByUser() {
    return this.baseService.get(this.serviceURL + 'api/Request/GetRequestsByUser');
}

  getRequestById(id: number) {      
      return this.baseService.get(this.serviceURL + 'api/Request/GetRequestByID/' + id);
  } 

  SaveRequest(requestDto: RequestDto) {      
      return this.baseService.post(this.serviceURL + 'api/Request/SaveRequest', requestDto);
  }

  SaveECFRequest(requestDto: RequestDto) {      
    return this.baseService.post(this.serviceURL + 'api/Request/SaveECFRequest', requestDto);
  }

  /*deleteRequest(id: number) {      
    return this.baseService.delete(this.serviceURL + 'api/Request/DeleteRequest/' + id);
  }

  updateRequest(acordMessage: AcordMessage) {      
      return this.baseService.put(this.serviceURL + 'api/Request/UpdateRequest', acordMessage);
  } */

  getResubmitRequestByAcordID(id: number) {      
    return this.baseService.get(this.serviceURL + 'api/Request/GetResubmitRequestByAcordID/' + id);
} 
}
