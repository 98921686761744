<div class="jumbotron">
<div class="card border">  
  <div class="card-header">
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-fill bd-highlight">
        <h3 >Submit Work Order</h3>
      </div>
      <div class="justify-content-end form-inline">
        <button class="btn btn-primary" (click)="openFileUpload()">Add Documents</button>        
      </div>
    </div>
  </div>
  <form [formGroup]="messageSubmitForm" (ngSubmit)="save()" novalidate>
    <div class="card-body border">      
      <mat-accordion multi>                
        <mat-expansion-panel (opened)="contactPanelState = true"
                              (closed)="contactPanelState = false"
                              [expanded]="contactPanelState">
          <mat-expansion-panel-header>
            <mat-panel-title><h4>Contact Details</h4></mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>            
          
          <div class="mt-3">
            <div class="form-group row">
               <div class="col-md-4">
                  <label class="control-label" for="technicianName">Technician Name<span class="required">&nbsp;*</span></label>
                  <input class="form-control full-width" type="text" formControlName="technicianName">
                  <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'technicianName')">Name is required</span>
                  <span class="text-danger" *ngIf="messageSubmitForm.hasError('minlength', 'technicianName')">Name must at least contain 5 characters</span>
              </div>
              <div class="col-md-4">
                <label class="control-label" for="telephone">Technician Telephone Number<span class="required">&nbsp;*</span></label>
                <input class="form-control full-width" type="text" formControlName="telephone">
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'telephone')">Telephone is required</span>
                <!-- <span class="text-danger" *ngIf="messageSubmitForm.hasError('telephone', 'maxlength')">Telephone have more than 5 characters</span>   ***************-->
              </div>
              <div class="col-md-4">
                <label class="control-label" for="email">Technician Email<span class="required">&nbsp;*</span></label>
                <input class="form-control full-width" type="text" formControlName="email">
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'email')">Email is required</span>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <br/>
        <mat-expansion-panel (opened)="workOrderPanelState = true"
                              (closed)="workOrderPanelState = false"
                              [expanded]="workOrderPanelState">
          <mat-expansion-panel-header>
            <mat-panel-title><h4>Work Order Details</h4></mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          
          <div class="mt-3">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="control-label" for="umr">UMR<span class="required">&nbsp;*</span></label>
                <input class="form-control full-width" type="text" formControlName="umr" readonly>
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'umr')">UMR is required</span>
                <!-- <span class="text-danger" *ngIf="messageSubmitForm.hasError('umr', 'minlength')">UMR must at least contain 5 characters</span> *************-->
              </div>
              <div class="col-md-4">
                <label class="control-label" for="xisTechnician">XIS Technician</label>
                <input class="form-control full-width" type="text" formControlName="xisTechnician">
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('minlength', 'xisTechnician')">XIS Technician must at least contain 5 characters</span>
              </div>
              <div class="col-md-4">
                <label class="control-label" for="additionalInformation">Additional Information</label>
                <input class="form-control full-width" type="text" formControlName="additionalInformation">
              </div>
            </div>
          </div>
  
          <div class="mt-3">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="control-label" for="submissionType">Submission Type / Operation<span class="required">&nbsp;*</span></label>
                <select class="form-control" formControlName="submissionType">
                  <option value="SubmitWorkOrder">SubmitWorkOrder</option>
                </select>
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'submissionType')">Submission Type is required</span>
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('minlength', 'submissionType')">Submission Type must at least contain 5 characters</span>
              </div>
              <div class="col-md-4">
                <label class="control-label" for="slipType">Slip Type<span class="required">&nbsp;*</span></label>
                  <select class="form-control" formControlName="slipType" (ngModelChange)="setFieldsDependOnSlipType($event)">                               
                      <option value="BindingAuthority">Binding Authority</option>
                      <option value="DirectInsurance">Direct Insurance</option>
                      <option value="ExcessOfLoss">Excess of Loss</option>
                      <option value="FacultativeReinsurance">Facultative Reinsurance</option>
                      <option value="ProportionalTreaty">Proportional Treaty</option>
                  </select>
                  <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'slipType')">Slip Type is required</span>
                  <span class="text-danger" *ngIf="messageSubmitForm.hasError('minlength', 'slipType')">Slip Type must at least contain 5 characters</span>
              </div>
              <div class="col-md-4">
                <label class="control-label" for="delink">DeLink<span class="required">&nbsp;*</span></label>
                <select class="form-control" formControlName="delink">
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'delink')">DeLink is required</span>
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('minlength', 'delink')">DeLink must at least contain 3 characters</span>                             
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="control-label" for="processingOption">Processing Required<span class="required">&nbsp;*</span></label>
                <select class="form-control" formControlName="processingOption">
                    <option value="premium_accounting">Premium Accounting</option>
                    <option value="premium_policy_processing">Premium Policy Processing (S&A)</option>
                    <option value="policy_only">Policy Only</option>
                    <option value="simultaneous_reinstatement">Simultaneous Reinstatement</option>
                    <option value="npe_review">NPE Review</option>
                    <option value="policy_endorsement_review">Policy Endorsement Review</option>
                </select>
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'processingOption')">Processing Required is required</span>
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('minlength', 'processingOption')">Processing Required must at least contain 5 characters</span>
              </div>
              <div class="col-md-4">
                <label class="control-label" for="bureau">Bureau<span class="required">&nbsp;*</span></label>
                  <select class="form-control" formControlName="bureau">
                    <option value="lloyds">Lloyd's</option>
                    <option value="companies">Companies</option>
                    <option value="mixed">Mixed</option>
                  </select>
                  <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'bureau')">Bureau is required</span>
                  <span class="text-danger" *ngIf="messageSubmitForm.hasError('minlength', 'bureau')">Bureau must at least contain 5 characters</span>
              </div>
              <div class="col-md-4">
                <label class="control-label" for="classOfBusiness">Class of Business<span class="required">&nbsp;*</span></label>
                <select class="form-control" formControlName="classOfBusiness">
                  <option *ngFor="let key of classOfBusiness.keys()" value={{classOfBusiness[key]}}>
                    {{key}}
                  </option>
                </select>
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('required', 'classOfBusiness')">Class of Business is required</span>
                <span class="text-danger" *ngIf="messageSubmitForm.hasError('min', 'classOfBusiness')">Class of Business must at least contain 1 </span>                            
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <br/>
        <mat-expansion-panel (opened)="otherPanelState = true"
                              (closed)="otherPanelState = false"
                              [expanded]="otherPanelState">
          <mat-expansion-panel-header>
            <mat-panel-title><h4>Other Details</h4></mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          
          <div class="mt-3">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="control-label" for="groupReference">Group Reference</label>
                <input class="form-control full-width" type="text" formControlName="groupReference">
              </div>
              <div class="col-md-4">
                <label class="control-label" for="groupNumber">Number in Group</label>
                <input class="form-control full-width" type="text" formControlName="groupNumber">
              </div>
              <div class="col-md-4">
                <label class="control-label" for="ucr">UCR</label>
                <input class="form-control full-width" type="text" formControlName="ucr">
              </div>
            </div>
          </div>
  
          <div class="mt-3">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="control-label" for="tr">TR</label>
                <input class="form-control full-width" type="text" formControlName="tr">
              </div>
              <div class="col-md-4">
                <label class="control-label" for="simChecked">Sim Signing Required?</label><br>
                <mat-slide-toggle formControlName="simChecked"></mat-slide-toggle>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <br/>
      <div class=" bd-highlight" *ngIf="showLoadedDocuments">
        <div class="p-2 flex-fill bd-highlight">
          <h4>Loaded Documents</h4>
        </div>
        <div>
          <mat-table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="fileName">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.fileName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="documentType">
                <mat-header-cell *matHeaderCellDef> Type<span class="required">&nbsp;*</span></mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-select [(value)]="element.documentType" required (selectionChange)="OnDocumentTypeChange($event, element.id)" placeholder="-- Select Document Type --">                  
                    <mat-option *ngFor="let key of documentTypes.keys(); let i = index" value={{documentTypes[key]}}>
                      {{key}}
                    </mat-option>
                  </mat-select>
                </mat-cell>
            </ng-container>
            <!-- <ng-container matColumnDef="confidential">
                <mat-header-cell *matHeaderCellDef> Confidential </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.confidential}} </mat-cell>
            </ng-container> -->
            <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.createdBy}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef> Created On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.createdOn}} </mat-cell>
            </ng-container>      
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <a (click)="onLoadedDocumentDelete(element.id)">
                                        <mat-icon mat-list-icon>delete</mat-icon>
                                    </a>                                  
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-cell>
            </ng-container>        
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </div>
      <br/>
      <div class=" bd-highlight" *ngIf="showUmrDocuments">
        <div class="p-2 flex-fill bd-highlight">
          <h4>UMR Documents</h4>
        </div>
        <div>
          <mat-table mat-table [dataSource]="umrDataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="fileName">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.fileName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="documentType">
                <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{GetDocumentTypeByValue(element.documentType)}} </mat-cell>                
            </ng-container>
            <ng-container matColumnDef="confidential">
                <mat-header-cell *matHeaderCellDef> Confidential </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.confidential}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.createdBy}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef> Created On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.createdOn}} </mat-cell>
            </ng-container>      
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-checkbox (change)="onUmrDocumentStatusChange($event, element.id)" [checked]="element.isSelected"> </mat-checkbox>
              </mat-cell>
            </ng-container>      
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  <div class="card-footer form-inline">
    <div class="btn-group mr-2">
      <button type="submit" class="btn btn-primary" [disabled]="clicked">Submit</button>        
    </div>            
    <div class="btn-group">        
      <button type="button"class="btn btn-primary" (click)="onBackToList()">Back to List</button>
    </div>
  </div>
</form>
</div>
</div>