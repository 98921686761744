<nav class="navbar navbar-light bg-light">
  <a class="navbar-brand" href="https://gpmdev.co.uk"><img src="../../../../assets/images/GPM/GPM_Logo_RGB.png" width="60" height="40"></a>
  <div class="left"></div>
  <img src="../../../../assets/images/GPM/Hub_1.png" width="40" height="40" class="mr-2"><h5 class="mb-0"><b>inFOCUS | HUB</b></h5>
  <div class="right"></div>
  <div style="width:60px"></div>
</nav>
<div class="wrapper fadeInDown">
  <div id="formContent">
    <!-- Tabs Titles -->

    <!-- Icon -->
    <div class="m-3">
      <img src="../../../../assets/images/GPM/Hub.svg" width="160" height="120">
    </div>

    <!-- Login Form -->
    <form >
      <input type="submit" class="fadeIn fourth" value="Log In" (click)="onLogin()">
    </form>
  </div>
</div>
