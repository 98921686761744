import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { User } from './shared/code/user';
import { AuthInfo, AuthService } from './shared/Authentication/auth.service';
import { environment } from 'src/environments/environment';

interface SideNavRoute {
  icon?: string;
  route?: string;
  title?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ACORD';
  isIframe = false;
  loginDisplay = false;
  mode = new FormControl('side');
  shouldRun = true;
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  version = environment.version;
  authInfo = new AuthInfo();
  readyToShowLogin = false;

  public myWorkRoutes: SideNavRoute[];
  public adminRoutes: SideNavRoute[];

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  closeMenu() {
    if (this.isExpanded) {
      //this.isExpanded=false;
      //this.isShowing=false;
      this.sidenav.close();
    }
  }
  private readonly _destroying$ = new Subject<void>();

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    // Set up a short delay before the login prompt can be shown, in case we're already authenticated
    setTimeout(() => {
      this.readyToShowLogin = true;
    }, 1000);

    this.authService.authEmitter.subscribe((ai) => {
      // console.log(`AuthInfo received: authenticated=${ai.authenticated}`);
      this.authInfo = ai;
    });
  }

  isLoggedIn(): boolean {
    return this.authInfo.authenticated;
  }

  showLoginPage(): boolean {
    return !this.isLoggedIn() && this.readyToShowLogin;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  loginEventHandler(value: any) {
    if (value == 'login') {
      this.authService.login();
    }
  }

  logoutEventHandler(value) {
    if (value == 'logout') {
      this.authService.logout();
    }
  }

  //     // The user
  get user(): User {
    return this.authInfo.user;
  }

  get groups(): string[] {
    return this.authInfo.groupNames;
  }

  get displayName(): string {
    return this.authInfo.displayName;
  }

  get email(): string {
    return this.authInfo.email;
  }

  get role(): string {
    return this.authInfo.appRoleName;
  }
}
