import { Dictionary } from '../app/shared/code/dictionary';

export const environment = {
  production: true,
  version: "2.1.1.0",
  infocusIMRAPI: 'https://infocushub-api.gpmdev.co.uk/', 
  OAuthSettings : {
    clientId: '56b2f954-7776-4332-a5dd-c3dc0c9ab32f',
    authority: 'https://login.microsoftonline.com/9952cb5a-8e3c-46f2-9932-7fdfd92bae1b', // This is your tenant ID
    validateAuthority: true,
    redirectUri: 'https://infocushub.gpmdev.co.uk/',
    postLogoutRedirectUri: "https://infocushub.gpmdev.co.uk/",
    navigateToLoginRequestUrl: true,
    scopes: [
        'user.read',
        'openid',
        'profile'
    ],
    appRoles: new Dictionary([
        { key: "652148f6-a4ca-477b-ba2c-916620c00fe2", value:"Broker" },
        { key: "fc3e3552-6b29-4f01-9615-63e7384ea67e", value:"Underwriter" },
        { key: "98839ea6-36da-4db8-ac87-11692ab5be81", value:"Administrator" },
        { key: "b10ce1e3-64ce-4bc3-944a-d0843fc337d7", value:"Technician" },
        { key: "daf84a5c-a6b0-4cf3-8811-4878066541bc", value:"ClaimsBroker" },
        { key: "b4b6923f-1354-4831-8983-c4ea124f3f59", value:"ClaimsTechnician" },
        { key: "ec77de16-63dd-444e-a088-6556e55cb393", value:"IBA" },
        { key: "9d103e1d-fef9-4e84-866d-ca97d66eecdd", value:"IBAManager" },
        { key: "044b3766-d6c8-41b4-b7c0-072803863ab4", value:"Compliance" },
        { key: "6b3eff47-62d6-4b46-a414-812639fd1e2e", value:"Auditor" }
    ]).toLookup(),
  }
};
