<div class="jumbotron">
    <div class="card">
        <div class= "card-header">
        <div class="d-flex bd-highlight">
            <div class="p-2 flex-fill bd-highlight">
                <h3>Details</h3>
                <p>{{ title }}</p>
            </div>
        </div>
        </div>
        <div class="card-body">
            <form [formGroup]="requestDetailForm">
                <div class="container-fluid">
                    <table class="table"> 
                        <tbody>
                            <tr class="d-flex">
                                <th class="th-details col-1">UMR:</th>
                                <td class="col-11">{{requestDetailForm.controls.umr.value}}</td>
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Submission Type:</th>
                                <td class="col-11">{{requestDetailForm.controls.submissionType.value}}</td>
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">XIS Technician:</th>
                                <td class="col-11">{{requestDetailForm.controls.xisTechnician.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Business Type:</th>
                                <td class="col-11">{{requestDetailForm.controls.businessType.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Bureau:</th>
                                <td class="col-11">{{requestDetailForm.controls.bureau.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Technician Name:</th>
                                <td class="col-11">{{requestDetailForm.controls.technicianName.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Telephone:</th>
                                <td class="col-11">{{requestDetailForm.controls.telephone.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Email:</th>
                                <td class="col-11">{{requestDetailForm.controls.email.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Contract Type:</th>
                                <td class="col-11">{{requestDetailForm.controls.contractType.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Contract Nature:</th>
                                <td class="col-11">{{requestDetailForm.controls.contractNature.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Class Of Business:</th>
                                <td class="col-11">{{requestDetailForm.controls.classOfBusiness.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Delink:</th>
                                <td class="col-11">{{requestDetailForm.controls.delink.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Processing Required:</th>
                                <td class="col-11">{{requestDetailForm.controls.processingRequired.value}}</td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Creation Date:</th>
                                <td class="col-11">{{requestDetailForm.controls.creationDate.value}}</td>                        
                            </tr>
                        </tbody>
                    </table>                
                </div>
                <br/>
                <div class="container-fluid">
                    <h4>Attached Documents</h4>
                    <table class="table">
                        <thead  class="d-flex">
                            <th class="col-3">Document Id</th>
                            <th class="col-3">Document Name</th>
                            <th class="col-2">Document Type</th>
                            <th class="col-2">Created By</th>
                            <th class="col-2">Created Date</th>
                        </thead>
                        <tbody formArrayName="documents">
                            <tr class="d-flex" *ngFor="let itemrow of requestDocs">
                                <td class="col-3">{{itemrow.documentID}}</td>
                                <td class="col-3">{{itemrow.fileName}}</td>
                                <td class="col-2">{{itemrow.documentType}}</td>
                                <td class="col-2">{{itemrow.createdBy}}</td>
                                <td class="col-2">{{itemrow.createdOn}}</td>
                            </tr>
                        </tbody>
                    </table>                
                </div>
            </form>
        </div>    
        <div class="card-footer">            
            <div class="btn-group">
            <button class="btn btn-primary" (click)="onBackToList()">Back to List</button>
            </div>
        </div>
    </div>
</div>