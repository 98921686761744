import { Component, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-loginPage',
  templateUrl: './loginPage.component.html',
  styleUrls: ['./loginPage.component.css']
})
export class LoginPageComponent implements OnInit {
   @Output() loginClicked = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onLogin() {
    this.loginClicked.emit("login");
  }

  // goToGPM() {
  //   window.location.href = "https://gpmdev.co.uk";
  // }
}
