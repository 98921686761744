import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AcordMessageService } from '../../../services/acordmessage.service';
import { RequestService } from '../../../services/request.service';
import { AcordDto } from '../../../shared/code/InfocusInterfaces';

@Component({
  selector: 'acord-messages',
  templateUrl: './acord-messages.component.html',
  styleUrls: ['./acord-messages.component.css']
})
export class AcordMessagesComponent implements OnInit, AfterViewInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource<AcordDto>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(private _acordMessageService: AcordMessageService,
              private _requestService: RequestService) { 
    
    this.displayedColumns = this.GetTableColumnHeadings();    
   }

  ngOnInit(): void {
      setTimeout(() => {
        this.getAcordMessagesByUser();
      }, 1000);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDelete(id: number)
  {
    var isOk = confirm("Do you really want to cancel workorder? ")
    if (isOk == true) {  
      this.cancelWorkOrder(id);
      this.getAcordMessagesByUser();
     }
  }
  
  getAcordMessagesByUser() {    
    this._acordMessageService.getAcordMessagesByUser().subscribe((data: AcordDto[]) => {
      this.dataSource.data = data;      
    })     
  }

  cancelWorkOrder(id: number) {    
    this._acordMessageService.cancelWorkOrder(id).subscribe((data: number) => {
         if(data == 0) {
          alert("Cancellation request failed");
         }
         else{
          alert("Cancellation request created successfully");
         }
    })     
  }

  GetTableColumnHeadings() : string []
  {
    return ['submissionType',
    'umr',
    'ucr',
    'tr',
    'sentDate',
    'technicianName',
    'createdBy',
    'operation',
    'status',
    'tStatus',
    'workOrderReference',
    'actions'
    ];
  }
}
