import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyBrokerService {
  serviceURL: string;

  constructor(private baseService: BaseService) {
    this.serviceURL = environment.infocusIMRAPI;
   }

  IsValidBroker(brokerId: string){
    return this.baseService.get(this.serviceURL + 'api/CompanyBroker/IsValidBroker/' + brokerId);
  }
  
}
