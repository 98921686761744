import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig  } from '@angular/material/dialog';
import { RequestService } from '../../../../services/request.service';
import { DocumentService } from '../../../../services/document.service';
import { CompanyBrokerService } from '../../../../services/companybroker.service';
import { RequestDto, DocumentDto, CompanyBrokerDto } from '../../../../shared/code/InfocusInterfaces';
import { FileUploadComponent } from '../../../../shared/components/file-upload/file-upload.component';
import { MatTableDataSource } from '@angular/material/table';
import { Dictionary } from '../../../../shared/code/dictionary';

@Component({
  selector: 'app-acord-message-submit',
  templateUrl: './acord-message-submit.component.html',
  styleUrls: ['./acord-message-submit.component.css']
})
export class AcordMessageSubmitComponent implements OnInit{  
  requestDto: RequestDto;  
  contactPanelState: boolean;
  workOrderPanelState: boolean;
  otherPanelState: boolean;
  errorMessage: any;
  displayedColumns: string[];  
  dataSource = new MatTableDataSource<DocumentDto>();
  umrDataSource = new MatTableDataSource<DocumentDto>();
  documentTypes: Dictionary;
  classOfBusiness: Dictionary;  
  showLoadedDocuments: boolean;
  showUmrDocuments: boolean;  
  bauth:string;
  dr :string;
  cat :string;
  messageSubmitForm: FormGroup;
  umrDocs: DocumentDto[];
  selectedUmrDocIds: number[]; 
  isValidBroker: boolean;
  clicked = false;
  docTypeWarning: string;

  constructor(private _formBuilder: FormBuilder,
              private dialog: MatDialog,
              private __avRoute: ActivatedRoute,
              private _router: Router,
              private _requestService: RequestService, 
              private _documentService: DocumentService,
              private _companyBrokerService: CompanyBrokerService) {
                this.messageSubmitForm = this._formBuilder.group({
                  technicianName: ['',[]],
                  telephone: ['',[]],
                  email: ['',[]],
                  umr: ['',[]],
                  xisTechnician: ['',[]],
                  additionalInformation: ['',[]],
                  submissionType: ['',[]],
                  slipType: ['',[]],
                  delink: ['',[]],
                  processingOption: ['',[]],
                  bureau: ['',[]],
                  classOfBusiness: ['',[]],
                  groupReference: ['',[]],
                  groupNumber: ['',[]],
                  simChecked: [false,[]],
                  ucr: ['',[]],
                  tr: ['',[]],
                  });
    this.contactPanelState = true;
    this.workOrderPanelState = true;
    this.otherPanelState = false;
    this.showLoadedDocuments = false;
    this.showUmrDocuments = false;
  }
  
  ngOnInit(): void {
    this.InitializeRequestDto();    
    this.displayedColumns = this.GetTableColumnHeadings();
    this.InitializeForm();
    setTimeout(() => {
    this.GetDocumentTypes();        
    this.cleanUnassignedDocuments();
    this.getAllUMRDocs();
        
      }, 1000);
    // delete any unassigned documents
   
  }  

  public hasError = (controlName: string, errorName: string) =>{
    return this.messageSubmitForm.controls[controlName].hasError(errorName);
  }

  openFileUpload(){    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';          
    dialogConfig.data = {
      from:'WorkOrder',
      umr: this.requestDto.umr,
      name: this.requestDto.technicianName,
      path: this.requestDto.documentPath
    }; 

    const dialogRef = this.dialog.open(FileUploadComponent, dialogConfig );
    // subscription on close
    dialogRef.afterClosed().subscribe(() => {
      /* if(dialogRef.componentInstance.isFilesAdded){
        this.showLoadedDocuments = true; */  
        this.getDocsByNewRequest();
      // }
    })  
  }

  toggleSimChecked()
  {
    this.requestDto.simChecked = !this.requestDto.simChecked;    
  }

  onLoadedDocumentDelete(id){    
    this._documentService.DeleteDocumentById(id).subscribe((data: number) => {
    this.getDocsByNewRequest();          
    })
  }

  OnDocumentTypeChange(event, id){
    const formData = new FormData();
    formData.append('DocumentType', event.value);
    this._documentService.UpdateDocumentType(id, formData).subscribe((data: number) => {
      this.getDocsByNewRequest();
    })
  }

  onUmrDocumentStatusChange(event, id){
    // const formData = new FormData();
    // formData.append('status', event.checked);
    // this._documentService.UpdateUMRDocStatus(id, formData).subscribe((data: number) => {
    //   //this.getAllUMRDocs();
    // })
    var doc = this.umrDataSource.data.find(d => d.id == id);
    doc.isSelected = event.checked;
  }
  
  onBackToList() {
    this._router.navigate(['/acordmessages']);
  }

  checkAtLeastOneDocSelected(): boolean {
    if (this.dataSource.data.length > 0) {
      return true;
    }

    for (let item of this.umrDataSource.data) {
      if (item.isSelected) {
        return true;
      }
    }

    return false;
  }

  checkDocumentTypeStatus() : boolean
  {
    for(let item of this.dataSource.data){
      if(item.documentType == null || item.documentType == '' || item.documentType == undefined){
        return false;
      }
    }
    return true;
  }

  checkCorrectDocumentTypes() : boolean
  {
    var inclusionTypePresent = false;
    var exclusionTypePresent = false;
    var lpanPresent = false;
    var pcFormPresent = false;

    if (this.messageSubmitForm.get('processingOption').value == 'npe_review' ||
      this.messageSubmitForm.get('processingOption').value == 'policy_endorsement_review' ||
      this.messageSubmitForm.get('processingOption').value == 'simultaneous_reinstatement') {
      return true;
    }

    for(let item of this.dataSource.data){
      if(this.messageSubmitForm.get('processingOption').value == 'premium_accounting' && item.documentType == 'lm_premium_advice_note'){
        inclusionTypePresent = true;
      }

      if(this.messageSubmitForm.get('processingOption').value == 'premium_accounting' && item.documentType == 'form_policy_control'){
        exclusionTypePresent = true;
      }

      if (this.messageSubmitForm.get('processingOption').value == 'premium_policy_processing' && item.documentType == 'lm_premium_advice_note') {
        lpanPresent = true;
      }

      if (this.messageSubmitForm.get('processingOption').value == 'premium_policy_processing' && item.documentType == 'form_policy_control') {
        pcFormPresent = true;
      }

      if(this.messageSubmitForm.get('processingOption').value == 'policy_only' && item.documentType == 'form_policy_control'){
        inclusionTypePresent = true;
      }

      if(this.messageSubmitForm.get('processingOption').value == 'policy_only' && item.documentType == 'lm_premium_advice_note'){
        exclusionTypePresent = true;
      }
    }

    for(let item of this.umrDataSource.data){
      if (item.isSelected) {
        if(this.messageSubmitForm.get('processingOption').value == 'premium_accounting' && item.documentType == 'lm_premium_advice_note'){
          inclusionTypePresent = true;
        }

        if(this.messageSubmitForm.get('processingOption').value == 'premium_accounting' && item.documentType == 'form_policy_control'){
          exclusionTypePresent = true;
        }

        if (this.messageSubmitForm.get('processingOption').value == 'premium_policy_processing' && item.documentType == 'lm_premium_advice_note') {
          lpanPresent = true;
        }

        if (this.messageSubmitForm.get('processingOption').value == 'premium_policy_processing' && item.documentType == 'form_policy_control') {
          pcFormPresent = true;
        }

        if(this.messageSubmitForm.get('processingOption').value == 'policy_only' && item.documentType == 'form_policy_control'){
          inclusionTypePresent = true;
        }

        if(this.messageSubmitForm.get('processingOption').value == 'policy_only' && item.documentType == 'lm_premium_advice_note'){
          exclusionTypePresent = true;
        }
      }
    }

    if (lpanPresent && pcFormPresent) {
      inclusionTypePresent = true;
    }

    if (inclusionTypePresent && !exclusionTypePresent) {
      return true;
    }
    else {
      this.docTypeWarning = "Ensure the appropriate document types have been selected:" + '\n' +
                            " - For Premium Accounting: include LPAN, but exclude Policy Control Form" + '\n' +
                            " - For Premium Policy Processing (S&A): include LPAN and Policy Control Form" + '\n' +
                            " - For Policy Only: include Policy Control Form, but exclude LPAN";

      return false;
    }
  }
  
  checkNoDuplicateFileNames() : boolean {
    var fileNames : string[] = [];

    for(let item of this.dataSource.data){
      if (item.isSelected && fileNames.indexOf(item.fileName) > -1) {
        return false;
      }
      else {
        fileNames.push(item.fileName);
      }
    }

    for(let item of this.umrDataSource.data){
      if (item.isSelected && fileNames.indexOf(item.fileName) > -1) {
        return false;
      }
      else {
        fileNames.push(item.fileName);
      }
    }

    return true;
  }

  save(){
    if(!this.messageSubmitForm.valid){
      return;
    }
    if(!this.checkAtLeastOneDocSelected()) {
      alert('Please ensure at least one document has been selected.');
      return;
    }
    if(!this.checkDocumentTypeStatus()) {
      alert('Select a Document Type for all documents as it is mandatory.');
      return;
    }
    if(!this.checkCorrectDocumentTypes()) {
      alert(this.docTypeWarning);
      return;
    }
    if(!this.checkNoDuplicateFileNames()) {
      alert('Multiple documents with the same name have been selected.');
      return;
    }

    this.clicked = true;

    this.requestDto.technicianName = this.messageSubmitForm.get('technicianName').value;    
    this.requestDto.telephone = this.messageSubmitForm.get('telephone').value.toString();
    this.requestDto.email = this.messageSubmitForm.get('email').value;
    this.requestDto.umr = this.messageSubmitForm.get('umr').value;
    this.requestDto.xisTechnician = this.messageSubmitForm.get('xisTechnician').value;
    this.requestDto.additionalInformation = this.messageSubmitForm.get('additionalInformation').value;
    this.requestDto.submissionType = this.messageSubmitForm.get('submissionType').value;
    this.requestDto.slipType = this.messageSubmitForm.get('slipType').value;
    this.requestDto.delink = this.messageSubmitForm.get('delink').value;
    this.requestDto.processingOption = this.messageSubmitForm.get('processingOption').value;
    this.requestDto.processingRequired = this.setProcessingRequired();
    this.requestDto.bureau = this.messageSubmitForm.get('bureau').value;
    this.requestDto.classOfBusiness = this.messageSubmitForm.get('classOfBusiness').value;
    this.requestDto.groupReference = this.messageSubmitForm.get('groupReference').value;
    this.requestDto.groupNumber = this.messageSubmitForm.get('groupNumber').value;
    this.requestDto.simChecked = this.messageSubmitForm.get('simChecked').value;
    this.requestDto.ucr = this.messageSubmitForm.get('ucr').value;
    this.requestDto.tr = this.messageSubmitForm.get('tr').value;

    for(let item of this.dataSource.data){
      this.requestDto.documents.push(item);
    }

    for(let item of this.umrDataSource.data){
      this.requestDto.umrDocuments.push(item);
    }

    this.SaveRequest();
  }

  SaveRequest(){    
    this._requestService.SaveRequest(this.requestDto).subscribe((data: number) => {      
      this.onBackToList();
    })
  }  

  cleanUnassignedDocuments() {
    this._documentService.DeleteUnassignedDocuments().subscribe((data: number) => {
      //alert(data);
    })    
  }

  getDocsByNewRequest() {
   // this.requestDto.umr = this.messageSubmitForm.get('umr').value;
    this._documentService.GetDocsByNewRequest(this.requestDto.umr).subscribe((data: DocumentDto[]) => {  
      if(data.length > 0){
        this.showLoadedDocuments = true;        
       }
       else{
        this.showLoadedDocuments = false;
       }      
      this.dataSource.data = data;      
           
    })     
  } 

  getAllUMRDocs() {    
    const formData = new FormData();
    formData.append('umr', this.requestDto.umr);    
    this._documentService.GetAllUMRDocs(this.requestDto.id, formData).subscribe((data: DocumentDto[]) => {      
      this.umrDataSource.data = data;      
       if(data.length > 0){
        this.showUmrDocuments = true;

        for(let item of this.umrDataSource.data){
          item.isSelected = false;
        }
       }
       else{
        this.showUmrDocuments = false;
       }      
    })     
  }

  checkBrokerId() {
    this._companyBrokerService.IsValidBroker(this.requestDto.senderIdentification).subscribe((data: boolean) => {      
      this.isValidBroker = data;      
       if(! this.isValidBroker){
        this._router.navigate(['shared/companybrokerstatus']);       
       }
    })
  }

  InitializeForm() { 
    this.messageSubmitForm = this._formBuilder.group({
      technicianName: [this.requestDto.technicianName, [Validators.required, Validators.minLength(5)]],
      telephone: [this.requestDto.telephone, [Validators.required]],
      email: [this.requestDto.email, [Validators.required, Validators.email]],
      umr: [this.requestDto.umr, [Validators.required]],
      xisTechnician: [this.requestDto.xisTechnician,[Validators.minLength(5)]],
      additionalInformation: [this.requestDto.additionalInformation,[]],
      submissionType: [this.requestDto.submissionType, [Validators.required, Validators.minLength(5)]],
      slipType: [this.requestDto.slipType,[Validators.required, Validators.minLength(5)]],
      delink: [this.requestDto.delink,[Validators.required, Validators.minLength(3)]],
      processingOption: [this.requestDto.processingOption,[Validators.required, Validators.minLength(5)]],
      bureau: [this.requestDto.bureau,[Validators.required, Validators.minLength(5)]],
      classOfBusiness: [this.requestDto.classOfBusiness,[Validators.required, Validators.min(1)]],
      groupReference: [this.requestDto.groupReference,[]],
      groupNumber: [this.requestDto.groupNumber,[]],
      simChecked: [this.requestDto.simChecked,[]],
      ucr: [this.requestDto.ucr,[]],
      tr: [this.requestDto.tr,[]],
      });      
  }

  InitializeRequestDto(){    
    this.requestDto = this.GetDefaultRequestObject();     
    this.__avRoute.queryParams.subscribe(params => {
        this.requestDto.umr = params['umr'];
        this.requestDto.technicianName = params['techname'];
        this.requestDto.telephone = params['techtelno'];
        this.requestDto.email = params['techemail'];
        this.requestDto.businessType = params['btype'];
        this.requestDto.documentPath = params['path'];
        this.requestDto.departmentType = params['depttype'];
        this.requestDto.accountTransactionType = params['tran'];
        this.requestDto.senderIdentification = params['broker'];
        // default values
        this.requestDto.slipType = 'BindingAuthority';
        this.requestDto.submissionType = 'SubmitWorkOrder';
        this.requestDto.delink = 'true';
        // other variables used to override default values
        this.bauth = params['bauth'];
        this.dr = params['dr'];
        this.cat = params['cat'];
               
      });

      this.getClassOfBusiness();
      //this.getProcessingRequired();
      this.getSlipType();
      this.setFieldsDependOnSlipType(this.requestDto.slipType);
      setTimeout(() => {
        this.checkBrokerId();      

            
          }, 1000);
  }

  GetDefaultRequestObject(): RequestDto  {    
    return {
      id: 0,      
      telephone: '',
      email: '',
      documentPath: '',
      contractType: '',
      contractNature: '',
      classOfBusiness: '',
      technicianName: '',
      businessType: '',
      umr: '',
      xisTechnician: '',
      delink: '',
      submissionType: '',
      groupReference: '',
      groupNumber: '',
      simChecked: false,
      ucr: '',
      tr: '',      
      departmentType: '',
      additionalInformation: '',
      bureau: '',
      processingRequired: '',
      requestId: 0,
      creationDate: '',
      workOrderRef: '',
      slipType: '',
      documents: [],
      coverType: '',
      isSent: false,
      fullPath: '',
      documentType: '',
      accountTransactionType: '',
      senderIdentification: '',
      companyBrokerId: 0,
      createdBy: '',
      processingOption: '',
      umrDocuments: []
    };
  }

  getClassOfBusiness(){
    this.requestDto.classOfBusiness = "";
    if(this.requestDto.departmentType)
    {      
      if(this.requestDto.departmentType == "M"){
        this.requestDto.classOfBusiness = "marine";
      }
      else if(this.requestDto.departmentType == "N"){
        this.requestDto.classOfBusiness = "nonmarine_general_and_miscellaneous_liability";
      }
      else if(this.requestDto.departmentType == "A"){
        this.requestDto.classOfBusiness = "aviation";
      }
    }
    
    this.classOfBusiness =  new Dictionary([
      { key: "Aviation", value: "aviation"},
      { key: "Marine", value: "marine"},
      { key: "Non-Marine", value: "nonmarine_general_and_miscellaneous_liability"}
    ]);
  }

  setProcessingRequired() : string {
    this.requestDto.processingRequired = "";
    // if(this.requestDto.accountTransactionType){
      if(this.messageSubmitForm.get('processingOption').value == 'premium_accounting'){
        if (this.requestDto.accountTransactionType == "PM" || this.requestDto.accountTransactionType == "RN" || this.requestDto.accountTransactionType == "MD")
          return "premium";
        else if(this.requestDto.accountTransactionType == "AP"){ 
          return "lm_additional_premium_ap";
        }
        else if(this.requestDto.accountTransactionType == "RP"){
          return "lm_return_premium_rp";
        }
        else {
          return "premium";
        }
      }

      if(this.messageSubmitForm.get('processingOption').value == 'premium_policy_processing' ||
         this.messageSubmitForm.get('processingOption').value == 'policy_only'){
        if (this.requestDto.accountTransactionType == "NB" || this.requestDto.accountTransactionType == "NP")
          return "premium";
        else if(this.requestDto.accountTransactionType == "AP"){ 
          return "lm_additional_premium_ap";
        }
        else if(this.requestDto.accountTransactionType == "RP"){
          return "lm_return_premium_rp";
        }
        else {
          return "premium";
        }
      }

      if(this.messageSubmitForm.get('processingOption').value == 'simultaneous_reinstatement'){
        if (this.requestDto.accountTransactionType == "AP" || this.requestDto.accountTransactionType == "RI")
          return "lm_reinstatement_ap";
        else if(this.requestDto.accountTransactionType == "RP"){ 
          return "lm_reinstatement_rp";
        }
        else {
          return 'lm_reinstatement_ap';
        }
      }

      if(this.messageSubmitForm.get('processingOption').value == 'npe_review'){
        return "NPE Review";
      }

      if(this.messageSubmitForm.get('processingOption').value == 'policy_endorsement_review'){
        return "Policy Endorsement Review";
      }
    // }       
  }

  getSlipType(){
    this.requestDto.slipType = "";
    if(this.bauth == "Y"){
      this.requestDto.slipType = "BindingAuthority";
    }
    else{
      if(this.dr){
        if(this.dr == "D"){
          this.requestDto.slipType = "DirectInsurance";
        }
        else if(this.dr == "R"){
          this.requestDto.slipType = "FacultativeReinsurance";
          if(this.cat) {
            if(this.dr == "R" && this.cat == "EX"){
              this.requestDto.slipType = "ExcessOfLoss";
            }
            else if(this.dr == "R" && this.cat == "TB"){
              this.requestDto.slipType = "ProportionalTreaty";
            }
            else if(this.dr == "R" &&  (this.cat != "EX" && this.cat != "TB")){
              this.requestDto.slipType = "FacultativeReinsurance";
            }
          }
        }
      }        
    }
  }

  setFieldsDependOnSlipType(slipType){   
   if(slipType == "BindingAuthority")
    {
      this.requestDto.contractType = "direct";
      this.requestDto.contractNature = "nonproportional";
      this.requestDto.businessType = "insurance";
      this.requestDto.coverType = "lm_balanced_binding_authority";
    }
    else if (slipType == "DirectInsurance")
    {
      this.requestDto.contractType = "direct";
      this.requestDto.contractNature = "nonproportional";
      this.requestDto.businessType = "insurance";      
    }
    else if (slipType == "ExcessOfLoss")
    {
      this.requestDto.contractType = "treaty";
      this.requestDto.contractNature = "excess_cession";
      this.requestDto.businessType = "reinsurance";      
    }
    else if (slipType == "FacultativeReinsurance")
    {
      this.requestDto.contractType = "facultative";
      this.requestDto.contractNature = "nonproportional";
      this.requestDto.businessType = "reinsurance";
    }
    else if (slipType == "ProportionalTreaty")
    {
      this.requestDto.contractType = "treaty";
      this.requestDto.contractNature = "proportional";
      this.requestDto.businessType = "reinsurance";      
    }
  }

  GetDocumentTypeByValue(type) {
    for (let key in this.documentTypes) {
      let value = this.documentTypes[key];
      if(value == type){
        return key;
      }
    }
  }

  GetDocumentTypes(): void {
    this.documentTypes =  new Dictionary([
    { key: "Agreed Wording", value: "wording_agreed"},
    { key: "Bordereau", value: "bordereau_premium"},
    { key: "Calculation", value: "calculation"},
    { key: "Endorsement", value: "lm_bureau_endorsement" },
    { key: "File Note", value: "document_file_note"},
    { key: "LPAN", value: "lm_premium_advice_note"},
    { key: "LPO208", value: "lm_lpo_208"},
    { key: "LPO301", value: "lpo301" },
    { key: "Other Document", value: "document"},
    { key: "Policy", value: "booklet_insurance_policy"},
    { key: "Premium Advice", value: "advice_premium"},
    { key: "Signed Placing Endorsement", value: "document_placing_endorsement_signed"},      
    { key: "Slip", value: "document_slip"},
    { key: "Treaty Statement", value: "document_broker_account"},
    { key: "Wording", value: "wording"},
    { key: "Wording Addenda", value: "wording_addenda"},
    { key: "XIS Correction Form", value: "form_correction"},
    { key: "XIS Policy Control Form", value: "form_policy_control"},      
    { key: "XIS Query Sheet", value: "form_query_response" }    
  ]);
}

  GetTableColumnHeadings() : string []
  {
    return ['fileName',
    'documentType',
    'createdBy',    
    'createdOn',
    'actions'
    ];
  }
}


