<div class="card">
    <div class="card-header">
        <div class="d-flex bd-highlight">
          <div class="p-2 flex-fill bd-highlight">
            <h3>Broker Id not configured for message submission. Please contact your administrator.</h3>
          </div>
        </div>        
    </div>
    <!-- <div class="card-body">        
    </div> --> 
    <div class="card-footer">
        <div class="btn-group">        
        <button type="button" class="btn btn-primary" (click)="onBackToList()">Back to List</button>
        </div>
    </div>
</div>
