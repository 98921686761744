import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AcordMessageService } from '../../../../services/acordmessage.service';
import { AcordMessage } from '../../../../shared/code/InfocusInterfaces';

@Component({
  selector: 'acord-message-detail',
  templateUrl: './acord-message-detail.component.html',
  styleUrls: ['./acord-message-detail.component.css']
})
export class AcordMessageDetailComponent implements OnInit {
  title:string;
  acordMessage:AcordMessage;
  id: number;
  acordMessageDetailForm: FormGroup;
  errorMessage: any;

  constructor(private _fb: FormBuilder, 
              private __avRoute: ActivatedRoute,
              private _router: Router, 
              private _acordMessageService: AcordMessageService) {
    if(this.__avRoute.snapshot.params["id"]){
      this.id = this.__avRoute.snapshot.params["id"];
    }
    this.acordMessageDetailForm = this._fb.group({
      id: 0,
      guid: ['', []],
      sentDate: new Date(),
      message: ['',[]],
      operation: ['', []],
      messageStatus: ['', []],
      technicalStatus: ['',[]],
      requestResponse: ['', []],
      retrieveResponse: ['', []],
      reference: ['',[]],
      workOrderReference: ['', []],
      umr: ['', []],
      technicianName: ['',[]],
      technicianEmail: ['', []],
      requestId: [0,[]],
      details: ['', []],
      request: ['', []],
    })    
  }

  ngOnInit(): void {
    if(this.id > 0) {
      this.title = 'Acord Message';
      setTimeout(() => {
        this.getAcordMessage();
      }, 1000);
    }
  }

  getAcordMessage() {
    this._acordMessageService.getAcordMessageById(this.id).subscribe((data: AcordMessage) =>
    this.acordMessageDetailForm.setValue(data), error => this.errorMessage = error)
    /* {
      
      console.log("data:" + data );
      this.acordMessage = data;
      console.log("this.acordMessage:" + this.acordMessage );      
    })    */  
  }

  onBackToList() {
    this._router.navigate(['/acordmessages']);
  }

}

/* export interface AcordMessage {
  id: number;
  guid: string;
  sentDate: Date;
  message : string;
  operation : string;
  messageStatus : string;
  technicalStatus : string;
  requestResponse : string;
  retrieveResponse : string;
  reference : string;
  workOrderReference : string;
  umr : string;
  technicianName : string;
  technicianEmail : string;
  requestId: number;
} */