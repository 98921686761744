<div fxFlex fxLayout fxLayoutAlign="center" fxHide.xs>
    <table >
        <tr>
            <th>
                <h3>Contact</h3>
            </th>
        </tr>
        <tr>
            <td>
                <address>
                    6th Floor, AMP House<br />
                    Dingwall Road, Croydon<br />
                    CR0 2LX<br />
                                
                    <strong>Phone:</strong>020 8686 9400<br />
                    <strong>Info:</strong><a href="mailto:info@gpmdev.co.uk">info@gpmdev.co.uk</a>
                </address>                
            </td>
        </tr>
    </table>
</div>
