import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { AcordMessage } from '../shared/code/InfocusInterfaces';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AcordMessageService {
  serviceURL: string;

  constructor(private baseService: BaseService) {
    this.serviceURL = environment.infocusIMRAPI;
   }

  getAcordMessages() {
      return this.baseService.get(this.serviceURL + 'api/AcordMessage/GetAcordMessages');
  }

  getAcordMessagesByUser() {
    return this.baseService.get(this.serviceURL + 'api/AcordMessage/GetAcordMessagesByUser');
}

  getAcordMessageById(id: number) {
      return this.baseService.get(this.serviceURL + 'api/AcordMessage/GetAcordMessageById/' + id);
  }

  //   getAcordDtoById(id: number) {
  //     return this.baseService.get(this.serviceURL + 'api/AcordMessage/GetAcordDtoById/' + id);
  // }

  // deleteAcordMessage(id: number) { 
  //     return this.baseService.delete(this.serviceURL + 'api/AcordMessage/DeleteAcordMessage/' + id);
  // }

  // updateAcordMessage(acordMessage: AcordMessage) {
  //     return this.baseService.put(this.serviceURL + 'api/AcordMessage/UpdateAcordMessage', acordMessage);
  // }

  // saveAcordMessage(acordMessage: AcordMessage) {
  //     return this.baseService.post(this.serviceURL + 'api/AcordMessage/AddAcordMessage', acordMessage);
  // }

  cancelWorkOrder(id: number) {
    return this.baseService.delete(this.serviceURL + 'api/AcordMessage/CancelWorkOrder/' + id);
  }

  IsMessageCancelled(id: number) {
    return this.baseService.get(this.serviceURL + 'api/AcordMessage/IsMessageCancelled/' + id);
  }

}
